<template>
  <div class="othenStatistics">
    <div class="statistics-title mb-22 dp-f jc-sb">
      <div class="fs-20 fw-b">其他数据统计</div>
      <tags @selectEmit="getCountData($event.value)" />
    </div>
    <count :counts=state.counts v-loading="countLoading"
          :element-loading-text="config.loading.text" :element-loading-spinner="config.loading.spinner"
          :element-loading-background="config.loading.background"></count>
  </div>
</template>

<script setup>
import{ref,reactive} from 'vue'
import tags from "@/views/home/components/tool/tags.vue"
import count from "@/components/count/count.vue"
import { getXialaListdata2 } from "@/utils/server/selectdata.js"
import { queryResourceTable } from "@/api/cancelToken.js"
import qs from "qs";
const countLoading = ref(false);//loading 显示
const state = reactive({
  counts:getXialaListdata2(['模板使用','证书发放','实名认证','短信发送'],[0,0,0,0])
})
// 获取统计数据
const getCountData = (type) => {
  countLoading.value = true
  queryResourceTable('/initiate-contract/contract/otherCount', qs.stringify({
    type: type
  })).then(res => {
    state.counts[0].value=res.data.templateCount
    state.counts[1].value=res.data.certificationCount
    state.counts[2].value=res.data.certificateCount
    state.counts[3].value=res.data.messageCount
    countLoading.value = false;
  }).catch((err) => {
    countLoading.value = false;
  })
}
getCountData(1)
</script>

<style lang="scss" scoped>
.othenStatistics {
  margin-top: 12px;
  height: 263px;
  background-color: #fff;
  padding: 20px 30px;
    &-title {
    display: flex;
    justify-content: space-between;
  }
}
</style>